<template>
  <form action="#" class="form" data-gw-tab="contacts" data-gw-tab-grp="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mail.png" alt="e-mail" />
        </div>
        <div class="field__title">Support</div>
      </div>
      <input type="text" class="input" value="support@la2dream.com" readonly />
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mes.png" alt="forum" />
        </div>
        <div class="field__title">Forum</div>
      </div>
      <div class="field__container" translate="no">
        <a
          href="https://forum.la2dream.com/staff/"
          class="a-link"
          target="_blank"
          >forum.la2dream.com</a
        >
      </div>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__skype.png" alt="skype" />
        </div>
        <div class="field__title">Administrator</div>
      </div>
      <div class="field__container" translate="no">
        <a href="skype:la2dream.com?add" class="a-link">la2dream.com</a>
      </div>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__skype.png" alt="skype" />
        </div>
        <div class="field__title">Tech. Support</div>
      </div>
      <div class="field__container" translate="no">
        <a href="skype:dream_neo?add" class="a-link">Dream_neo</a>
      </div>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__skype.png" alt="skype" />
        </div>
        <div class="field__title">Web Dev.</div>
      </div>
      <div class="field__container" translate="no">
        <a href="https://join.skype.com/invite/lbSFTO9kGRAq" class="a-link"
          >BRABUS</a
        >
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style scoped></style>
